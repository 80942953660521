<template>

    <div class="img">
        <img :src="'/MiddleImg/'+ image " alt="..." class="img"/>
    </div>

</template>

<script>
export default {
    name: "Technical",
    props: {
        image: {
            type: String,
            required: true,
        },
    },
}

</script>

<style scoped>
.img {
    max-width: 284px;
    min-width: 284px;
    max-height: 274px;
    line-height: 274px;
}
.img[data-v-fa09ed3a] {
    margin-right: 0;
}
</style>
