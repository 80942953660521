<template>
    <div>
        <Quote/>

        <div class="course-title-box">
            <p class="course-title pt-5 col-10">Middle Full Stack Web Developer</p>
            <p class="course-subtitle mt-4 pb-5 col-10">
                Junior kursini bitirgach bu kursda o'qib,
                <br/>darajangizni middle darajasigacha yetkazishingiz mumkin.
            </p>
        </div>


        <div class="technologies pt-5">

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="docker.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Docker</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Ilgari web-dasturlar to'g'ri ishlashi uchun: Linux, Nginx, MySQL, PHP, Cron, NodeJS va boshqa
                        o'nlab dasturlar o'rnatilardi va dasturga moslanardi. Bu ancha vaqt talab qilardi va moslamadagi
                        kichik farq ham dasturning noto'g'ri ishlashiga olib kelardi. Docker esa dasturning o'zida
                        barcha moslamalar va kerakli infrastrukturani beradi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="vds.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">VDS</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Ilgari web-dasturlar uchun hosting'lar ishlatilardi. Hosting bu serverdagi bir papka xolos. Bir
                        hostingda 1000 lab dasturlar ishlaydi va bittasining buzilishi, qolganlarini ham hacker'lar
                        hujumiga zaif qilib qo'yadi. Hosting - juda eskirgan variant. Hozir VDS lar ishlatiladi. Bu
                        virtual server bo'lib, bizga to'laqonlik boshqaruvni beradi. Sizning dasturingiz alohida,
                        boshqa dasturlardan izolyatsiya holatida turadi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="ssh.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">SSH</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Masofada turgan serverni, o'zingizning kompyuteringizdan boshqarish imkonini beradi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="linux.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Linux</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Operatsion tizimini o'rganamiz. Bilasiz 80% serverlar linux tizimida ishlaydi. Yaxshi dasturchi
                        linux tizimini ham yaxshi bilishi kerak deb hisoblayman.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="apt-get.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Apt-Get</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Apt, apt-get va aptitude kabi paket menejerlari orqali tizimga istagan dasturingizni o'rnatish,
                        ularni boshqarishingiz mumkin.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="cron.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">CRON</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Dasturlash - biznes jarayonlarini avtomatlashtirish degani. Cron orqali siz istagan vaqtda biror
                        dasturni chaqirish imkoningiz bo'ladi. Misol uchun cron'ga "Har kuni soat 13:30 da
                        foydalanuvchilarga xat yubor", yoki "Har oyning 15-sanasida statistikani hioblab chiq"
                        - deyishingiz mumkin.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="gitlab.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Gitlab CI/CD</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Bu ko'proq dasturchining emas, DevOps mutaxassisining ishi bo'lsada, professional dasturchilar
                        bu
                        narsalarni bilishi shart. Kursda siz gitlab ga yuborgan kodlaringiz avtomatik ravishda, shoxadan
                        kelib chiqib developer, yoki production serverlarida joylashish jarayonlarini
                        avtomatlashtirasiz.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="design-pattern.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Design Patterns</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Junior kursida design patterns'lar bilan tanishdingiz. Endi yanada ko'proq
                        pattern'larni o'rganamiz.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="solid.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">SOLID</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Siz kod yozishni o'rgandingiz. Endi ko'p yillar davomida eskirmaydigan kod yozishni o'rganasiz.
                        Buning uchun SOLID prinsiplari kerak bo'ladi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="redis.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Redis</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Siz SQL tillarini qo'llovchi MOBT larni o'rgandingiz. Endi, No SQL turidagi MOBT ni o'rganamiz.
                        U ma'lumotlarni tezkor xotirada saqlaydi va dasturingizni tezlashtirish uchun ajoyib qurol
                        hisoblanadi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="rabbit.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">RabbitMQ</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Eng kuchli xabarlar brokeri. Har-xil topshiriqlarni navbatga qo'yish imkonini beradi. Katta
                        dasturlar bu qurolsiz ishlay olmaydilar.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="jobs.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Jobs & Workers</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Freymvork'da topshiriqlarni bajarish va boshqarish uchun job (ish) lar va worker (ishchi) lar
                        yaratish kerak bo'ladi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="regex.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">RegExp</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Biror matnni qolip orqali qidirish uchun Regular Expression'ni o'rganish kerak bo'ladi. Regular
                        Expression barcha tillarda keng qo'llaniladi va matn bilan ishlash uchun tengsiz hisoblanadi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="http.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">HttpClient</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Junior kursda axios'ni o'rgandingiz. Lekin boshqa serverlarga murojaat qilish nafaqat front'da,
                        balki back dasturlari uchun ham kerak. HttpClient orqali symfonyda serverlarga murojaat
                        qilishni o'rganamiz.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="parsing.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Parsing</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Dasturingiz har-xil saytlardan ma'lumotlarni qidirib, sizning ma'lumotlar-omboringizga
                        joylashishini istaysizmi? Demak - Parsing'ni o'rganish aynan siz uchun.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="php-traits.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">PHP Traits</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Dasturlashda faqat bir sinfni kengaytirish mumkin. Bu muammoni hal qilish uchun PHP tilida
                        trait'lar ishlatiladi.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInRight">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="symfony.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Symfony Advanced</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Back-end uchun eng kuchli freymvork - Symfony'ni yanada chuqurroq o'rganamiz.
                    </p>
                </div>
            </div>

            <div class="row d-sm-flex mb-5 fadeInLeft">
                <div class="col-0 col-sm-0 col-md-0 col-lg-2"></div>
                <div class="d-flex justify-content-center col-12 col-md-5 col-lg-3 text-center p-0 m-0">
                    <Technical image="VueJS.png"/>
                </div>
                <div class="col-12 col-sm-12 col-md-7 col-lg-6 mt-5 mt-md-auto p-0 m-0 my-auto">
                    <h2 class="font px-5 px-sm-4 mx-2 mb-3">Vue Advanced</h2>
                    <p class="font px-5 px-sm-4 mx-2 align p-0 m-0">
                        Front-end dasturlari uchun Vuejs qanchalik muhimligini yaxshi bilasiz. Kursda ushbu freymvorkni
                        yanada kengroq o'rganamiz.
                    </p>
                </div>
            </div>

        </div>

        <div class="course-title-box mb-5">
            <p class="course-title pt-5 col-10">
                Hamma dasturlashni o’rganishi shart.<br> Chunki u sizga o’ylashni o’rgatadi.
            </p>
            <p class="course-subtitle mt-4 pb-5 col-10">© Steve Jobs</p>
        </div>

        <div class="d-flex justify-content-end fixed-bottom mb-5 me-5 course-reg-button">
            <a
                id="goToPurchase"
                class="btn btn-secondary p-2 col-6 col-sm-4 col-md-3 col-lg-2"
                href="#payment"
                @click="toDown"
            >
                Kursga yoziling
            </a>
        </div>

        <div class="information col-11 col-lg-9 pb-5 mt-4">
            <div>
                <h5 class="course-title ms-5 course-information">
                    <b>Kursda qatnashish uchun nimalar kerak bo’ladi?</b>
                </h5>
                <ul class="divp6">
                    <li><b>"Junior Full Stack Web Developer"</b> kursini tugatgan bo'lishingiz kerak.</li>
                </ul>
            </div>

            <div>
                <h5 class="course-title ms-5 course-information mt-4"><b>Kurs vaqti</b></h5>
                <ul class="divp6">

                    <li><b>Haftasiga 2 kun</b></li>

                    <li>
                        <b>Davomiyligi 2 soat:</b> Toshkent vaqti bilan soat 19:00 dan 21:00 gacha. Yaxshi dasturchi
                        bo’lish uchun dars bo’lmagan kunlar ham 2 soatdan vaqt ajratish maslahat beriladi.
                    </li>

                    <li>
                        <b>Dars videoga yozib boriladi.</b> Agar biror sababga ko’ra darsda qatnasha olmasangiz video
                        sizga yuboriladi. Lekin, ko’p darsga qatnasha olmasangiz, siz bilan xayrlashishga to’g’ri
                        kelishi mumkin. Chunki faqat video orqali o’rgansangiz – bu kurs siz uchun oddiy video-darslik
                        bo’lib qoladi. Biz esa bunga qarshimiz.
                    </li>
                </ul>
            </div>

            <div>
                <h5 class="course-title ms-5 course-information mt-4"><b>Kurs haqida</b></h5>
                <ul class="divp6">

                    <li><b>Kurs davomiyligi:</b> 5 oy.</li>

                    <li>
                        <b>To’lov turlari:</b> O'zbekistonlik o'quvchilar uzcard yoki Humo kartalari orqali saytimizdan
                        to'lov qilishlari mumkin. Buning uchun
                        <a
                            class="hover-decoration"
                            href="#payment"
                            @click="toDown"
                        >
                            bu yerga bosing.
                        </a>
                        Chet eldagi vatandoshlar mastercard yoki visa kartalariga pul o’tkazish orqali to’lovni amalga
                        oshirishlari mumkin bo'ladi.
                    </li>
                    <li><b>Kurs tili:</b> o’zbekcha.</li>

                    <li v-if="this.course.teacher.givenName === null">
                        <b>O’qituvchi:</b> Akmal Kadirov.
                    </li>
                    <li v-else>
                        <b>O’qituvchi:</b> {{ this.course.teacher.givenName + ' ' + this.course.teacher.familyName }}
                    </li>

                    <li><b>Kursga yozilish uchun:</b> 100% oldindan, yoki har oy bo'lib to'lashingiz mumkin.</li>
                </ul>
            </div>
            <div>
                <h5 class="course-title ms-5 course-information mt-4"><b>Kursdan maqsad</b></h5>
                <ul class="divp6">
                    <li>
                        5 oy mobaynida bilimlaringizni oshirib, sizni tajribali Middle dasturchi darajasiga olib
                        chiqish.
                    </li>
                </ul>
            </div>
            <div>
                <h5 class="course-title ms-5 course-information mt-4"><b>Kursni tugatgach</b></h5>
                <ul class="divp6">
                    <li>
                        Bizning kompanyamizda 2 oylik amaliyot (практика) o'taysiz. Bunda siz, kurs davomida olgan
                        bilimlaringizni haqiqiy loyihalarda sinash imkoniga ega bo'lasiz.
                    </li>
                </ul>
            </div>
        </div>

        <div class="Tech pb-5" style="height: auto">
            <div class="row mb-2 col-11 px-3 px-sm-2 px-lg-5">
                <div class="fade-in heading ps-2">
                    <h1 style="font-size: 24px">Akmal Kadirov’da malaka oshirish: Middle Full Stack Web Developer</h1>
                    <p class="mt-3 mb-3">Buning uchun Junior Full Stack Web Developer <br> kursini tugatgan bo’lishingiz
                        kerak.</p>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Docker"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="VDS"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="SSH"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Linux OS"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Apt-Get"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Cron"/>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Gitlab CI_DI"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Design Patterns"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="SOLID"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Redis"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Rabbit MQ"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Jobs & Workers"/>
                </div>
                <div class="row justify-content-center p-0">
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Regular Expression"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="HTTP Client"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Parsing"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="PHP Traits"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Symfony Advanced"/>
                    <Tech class="col-sm-6 col-md-4 col-lg-3 col-xl mb-2 mx-2 my-2" type="Vue Advanced"/>
                </div>
            </div>
        </div>

        <div>
            <b-modal ref="modal-promo" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Bunday promo kod mavjud emas yoki amal qilish muddati tugagan</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    size="md"
                    style="width: 100px"
                    variant="btn btn-primary"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="can-not-bought" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Siz web-full-stack-junior kursining barcha modullarini sotib olmagansiz.</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    size="md"
                    style="width: 100px"
                    variant="btn btn-primary"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Bu kurs uchun o'quvchilar soni to'lgan</h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    size="md"
                    style="width: 100px"
                    variant="btn btn-primary"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="you-payed" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Siz allaqachon bu kurs uchun to'lov qilgansiz </h3>
                </div>
                <b-button
                    class="mt-3 float-end"
                    size="md"
                    style="width: 100px"
                    variant="btn btn-primary"
                    @click="hideErrorModal"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div
            id="payment"
            :class="hasActiveCourses ? 'border-module' : ''"
            class="col-11 col-sm-10 col-md-8 mx-auto mt-5 mb-2 p-3 form-register"
        >
            <h6 v-if="hasActiveCourses" class="text-center">
                Kursga yozilish
            </h6>

            <div v-if="getModules.length" class="my-3">
                <strong>Kursni tanlang</strong>
                <b-form-select
                    id="exampleInputId1"
                    v-model="course.moduleId"
                    class="form-control"
                    name="hero"
                >
                    <option
                        v-for="course in getModules"
                        :key="course.id"
                        :value="course.id"
                    >
                        {{course.name}}
                    </option>
                </b-form-select>
            </div>
            <label
                v-for="course of this.getModule.courses"
                :key="course.id"
                :for="course.id"
                class="d-block"
            >
                <div
                    v-if="hasActiveCourses"
                    :class="!course.isActive || !course.countOfStudents > 0 ? 'text-danger' : ''"
                    class="my-4 d-block d-md-flex bg-color"
                >

                    <div class="d-flex col-11 col-md-6 pt-2">
                        <div>
                            <input
                                :id="course.id"
                                v-model="boughtCourse.courses"
                                :disabled="isDisabledCheck(course) || !course.isActive || !course.countOfStudents > 0"
                                :value="course.id"
                                class="mr-2 course-check"
                                type="checkbox"
                                @change="checkAction($event)"
                            />
                        </div>

                        <div id="payment" class="form-register">
                            <strong>
                                <p class="course-check">{{ course.name }}</p>
                            </strong>
                        </div>
                    </div>

                    <div class="d-block col-11 col-md-6 pt-1 pt-md-1 m-0">
                        <div class="border-cover">
                            <strong>Boshlanish vaqti:</strong>
                            {{ course.startMonth.name }}
                        </div>
                        <div class="border-cover">
                            <strong>Narxi:</strong>
                            <!--
                            Yangi kurs payti to'g'irlash kerak
                            {{ numberWithCommas(course.price / 100) }} so'm
                            -->
                            {{numberWithCommas(10790000)}} so'm
                        </div>
                        <div
                            v-if="course.countOfStudents !== course.members && (course.countOfStudents - course.members) > 0"
                            class="border-cover mb-3 mb-md-0">
                            <strong>
                                <strong class="text-primary">{{ course.countOfStudents }}</strong> ta joydan
                                <strong class="text-danger">
                                    {{ course.countOfStudents - course.members }}
                                </strong>
                                ta qoldi
                            </strong>
                        </div>

                        <div v-else class="border-cover mb-3 mb-md-0">
                            <strong>
                                Ushbu Kursda joy qolmadi.
                            </strong>
                        </div>
                    </div>
                </div>
            </label>

            <div v-if="hasActiveCourses && showPromoCodeInput" class="d-flex mb-2">
                <div class="col-8 col-md-9 pt-1">
                    <input
                        v-if="isAuthenticated"
                        v-model="promo"
                        :disabled="myBoughtCourses >= boughtCourse.courses.length"
                        class="form-control mr-2"
                        placeholder="Promokod"
                    />
                </div>
                <div class="col-4 col-md-3 pt-1">
                    <button
                        v-if="isAuthenticated"
                        :disabled="myBoughtCourses >= boughtCourse.courses.length"
                        class="btn btn-secondary ml-2"
                        @click="findPromo"
                    >
                        Tekshirish
                    </button>
                </div>
            </div>

            <div v-else-if="isAuthenticated && hasActiveCourses" @click="showPromoInput">
                <a class="hover-decoration promo-text">Menda promo kod mavjud</a>
            </div>

            <div v-if="getFoundPromo !== null" class="col-12 col-sm-12 col-md-10 mt-5">
                <p>
                     Ushbu promo kod orqali siz
                    <span
                        v-for="(item, index) in getFoundPromo.promos"
                        :key="index"
                        class="course-color"
                    >
                        {{ item.name }}
                    </span>
                    modullari uchun
                    <span class="discount">{{ numberWithCommas(getFoundPromo.discount / 100) }}</span>
                    <span v-if="getFoundPromo.isPercent"> % </span>
                    <span v-else>
                        so'mdan, jami
                        <span class="discount">
                            {{ numberWithCommas(totalSum()) }} so'm
                        </span>
                    </span>
                    chegirmaga ega bo'lasiz!
                </p>
            </div>
            <div v-if="hasActiveCourses" class="d-sm-flex">
                <div v-if="!promo.length" class="col-12 col-sm-8 col-md-9">
                    <div v-if="totalPrice" class="mt-2 pt-2 fs-5 pr-5">
                        <div class="text-bold">
                            Jami: 10 790 000 so'm.
                        </div>
                        <div class="text-success text-bold">
                            <br/>
                            Kursni summasini to'liq to'lov qilishda chegirma 27%
                        </div>
                        <div>
                            <br />
                            <div class="text-bold">
                                Jami: {{ numberWithCommas(totalPrice / 100) }} so'm.
                            </div>
                            <div style="font-size: 16px">
                                Kurs summasini
                                <strong style="font-size: 16px">
                                    <!--
                                        Yangi kurs payti to'g'irlash kerak
                                    -->
                                    12 oyga {{numberWithCommas(10790000 / 12).slice('0', '7')}} so'mdan
                                </strong>
                                bo'lib to'lash mumkin. Buning uchun adminitstrator bilan bog'laning:
                                <div>
                            <span style="margin-right: 15px">
                            <i class="bi bi-telephone mr-1"></i>
                            <a
                                class="hover-decoration mb-4 mt-4"
                                :href="formatPhoneNumber('+998901302050')"
                            >
                                +998 90 130-50-20
                            </a>
                            </span>
                                    <span>
                                <i class="bi bi-telegram mr-1"></i>
                            <a
                                class="hover-decoration"
                                href="https://t.me/kadirovDevAdmin"
                                target="_blank"
                            >
                                @kadirovDevAdmin
                            </a>
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="totalPrice > 0" class="col-12 col-sm-8 col-md-9">
                    <p class="mt-2 pt-2 fs-5 pr-5 text-bold">
                        Jami: {{numberWithCommas(totalPrice /100) * 0.27}} so'm
                        {{ numberWithCommas(totalPrice / 100) }} so'm
                    </p>
                </div>
                <div class="col-4 col-md-3 ml-auto mt-auto pt-2">
                    <button
                        v-if="isAuthenticated"
                        :disabled="myBoughtCourses >= boughtCourse.courses.length"
                        class="btn btn-primary ml-2"
                        @click="bought"
                    >
                        To'lash
                    </button>
                </div>
            </div>
            <div v-else-if="loading" class="text-center mt-5">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden"> Loading... </span>
                </div>
            </div>
            <div v-else class="text-center mt-5">
                <h4>Hozirda aktiv web-full-stack-middle kurslarimiz mavjud emas</h4>
            </div>

            <!-- Start Payme Form -->
            <form :action="paymeUrl" class="d-none" method="POST">

                <!-- Payme Cashbox ID  -->
                <input :value="paymeLogin" name="merchant" type="hidden"/>

                <!-- Cost with tiyin -->
                <input :value="payme.amount" name="amount" type="hidden"/>

                <!-- Payment data -->
                <input :value="payme.transactionId" name="account[transactionId]" type="hidden"/>

                <!-- === OPTIONAL DATA === -->
                <!-- Language. By default 'ru'. Available options: ru|uz|en -->
                <input name="lang" type="hidden" value="uz"/>

                <!--
                    Currency. By default '860'. Available options: 643|840|860|978
                    643 - RUB
                    840 - USD
                    860 - UZS
                    978 - EUR
                -->
                <input name="currency" type="hidden" value="860"/>

                <!--
                    URL to redirecting after payment. By default, payme redirects to URL of Referer header value.
                    URL may contain that will be replaced by Payme:
                    :transaction - id of transaction. Can be null if payme couldn't create transaction
                    :account.{field} - field of account object
                    For example: https://your-service.com/payme/:transaction
                -->
                <!--
                    <input type="hidden" name="callback" value="{{ REDIRECT_URL }}"/>
                -->

                <!-- Redirect timeout after successful payment in milliseconds  -->
                <input name="callback_timeout" type="hidden" value="15"/>

                <!--
                    Payment description. You can also specify descriptions in few
                    languages by using description object like name="description[{lang}]".
                    As {lang} you can use ru, en or uz
                -->
                <input :value="payme.description" name="description" type="hidden"/>

                <!--
                    Details of payment. You can use JSON object encoded by BASE64.
                    For example:
                    {
                        "discount": {
                             "title": "discount 5%",
                             "price": 10000
                        },
                        "shipping": {
                              "title": "Shipment to Termez 28/23",
                              "price": 500000
                        },
                        "items": [
                            {
                                "title": "Tomato",
                                "price": 505000,
                                "count": 2
                            }
                        ]
                    }
                 -->
                <input :value="payme.detail" name="detail" type="hidden"/>

                <button ref="paymeButton" type="submit">Pay with <b>Payme</b></button>
            </form>

        </div>

        <div v-if="!isAuthenticated" id="payment" class="sent course pb-2 form-register">
            <div class="d-flex">
                <h5>Kursga yozilish uchun avval
                    <router-link class="login" to="/login">
                        tizimga kiring
                    </router-link>
                    <span class="login"> yoki </span>
                    <router-link class="login" to="/registration">
                        ro'yxatdan o'ting
                    </router-link>
                </h5>
            </div>
        </div>

        <div v-else class="advice pt-0 mt-5">
            <div class="divp7">
                <p>
                    <strong><em>Maslahat: </em></strong>
                    To'lovni amalga oshirganingiz, bu yaxshi dasturchi bo'ldingiz degani emas!
                    Sizga berilayotgan bilimlarni yaxshi o'zlashtirishga harakat qiling. Dasturlashni o'rganishga
                    bir kunda kamida ikki soat vaqtingizni ajrating. Sizga bilim berayotgan ustozingizni barcha
                    bergan
                    topshiriqlarini o'z vaqtida bajarsangiz, albatta siz kelajakda yaxshi dasturchi bo'lasiz.
                    Erinchoqlik yoki o'qishga nisbatan mas'uliyatsizlik qilsangiz, ustozingiz qanchalik yaxshi bilim
                    bermasin, siz kelajakda dasturchi bo'lolmasligingiz mumkin.
                </p>
            </div>
        </div>

        <div class="senter pt-0">
            <div class="divp7">
                Kurs haqida savollaringiz qolgan bo’lsa, yoki to’lovni amalga oshirishda muammolar bo’lsa -
                administratorimizga murojaat qilishingiz mumkin. <br/><br/> Administrator bilan quyidagi
                <strong> instagram: </strong>
                <a
                    class="hover-decoration"
                    href="https://www.instagram.com/kadirovdevadmin/"
                    target="_blank"
                >
                    @kadirovDevAdmin
                </a>,
                <strong> telefon raqam: </strong>
                <a
                    class="hover-decoration"
                    :href="formatPhoneNumber('+998901305020')"
                    target="_blank"
                >
                    +998 90 130-50-20
                </a>,
                <strong> telegram: </strong>
                <a
                    class="hover-decoration"
                    href="https://t.me/kadirovDevAdmin"
                    target="_blank"
                >
                    @kadirovDevAdmin
                </a> orqali bog'lanish mumkin.
            </div>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";
import Tech from "@/components/html/Tech";
import Technical from "@/components/html/Technical";

export default {
    name: "CoursePage",
    components: {Quote, Technical, Tech},
    computed: {
        ...mapGetters([
            'getTakeJuniorCourses',
            'getToken',
            'getBoughtCourse',
            'getOneCourse',
            'isAdmin',
            'getFoundPromo',
            'getCourseById',
            'getTeachersList',
            'getUserInfo',
            'getModules',
            'getMyMiddleCourses',
            'getFindCourses',
            'getMineBoughtCoursesList',
            'getModule',
            'getStudentCourses',
            'getUserCourses'
        ]),
        isAuthenticated() {
            return this.getToken
        }
    },
    data() {
        return {
            paymeUrl: process.env.VUE_APP_PAYME_URL,
            paymeLogin: process.env.VUE_APP_PAYME_LOGIN,
            showPromoCodeInput: false,
            boughtCourse: {
                courses: []
            },
            myBoughtCourses: [],
            totalPrice: 0,
            diffTotalPrice: 0,
            hasDiscount: false,
            payme: {
                amount: 0,
                price: 0,
                transactionId: 0,
                description: 'Kompyuter texnologiyalari sohasida maslahat xizmatlari',
                detail: ''
            },
            detailDto: {
                items: [
                    {
                        count: 1,
                        title: 'Kompyuter texnologiyalari sohasida maslahat xizmatlari',
                        price: null,
                        code: '10305013001000000',
                        package_code: '1545643',
                        vat_percent: 0
                    }
                ],
            },
            interval: '',
            promo: '',
            validPromo: '',
            show: true,
            course: {
                name: '',
                moduleId: null,
                startMonth: '',
                price: '',
                availableCount: '',
                moduleIndex: 0,
                teacher: {
                    givenName: null,
                    familyName: null
                }
            },
            hasActiveCourses: false,
            loading: true,
        }
    },
    methods: {
        ...mapActions([
            'fetchBoughtCourse',
            'searchCourse',
            "searchOneCourse",
            'addStudentToCourse',
            'findPromoCode',
            'fetchTeachers',
            'fetchUser',
            'fetchTakeCoursesJunior',
            'findOneCourseMiddle',
            'fetchModulesMiddle',
            'fetchMyMiddleBoughtCourses',
            'fetchMineBoughtCoursesNew',
            'fetchModule',
            'fetchStudentCourses',
            'fetchUserCourses'
        ]),
        showPromoInput() {
            this.showPromoCodeInput = true
        },
        formatPhoneNumber(number) {
            return `tel:${number}`
        },
        isDisabledCheck(course) {
            for (let studentCourse of this.getUserCourses) {
                if (course.id === studentCourse.course.id) {
                    return true;
                }
            }

            return false;
        },
        totalSum() {
            return this.getFoundPromo.discount * this.getFoundPromo.promos.length / 100
        },
        checkAction(e) {
            let id = e.target._value

            if (e.target.checked) {
                this.getModule.courses.map(course => {
                    if (!this.boughtCourse.courses.find(c => c === course.id) && course.id < id && course.isActive) {
                        this.boughtCourse.courses.push(course.id)
                    }
                })
            } else {
                this.getModule.courses.map(course => {
                    if (course.id > id) {
                        for (let i = 0; i < this.boughtCourse.courses.length; i++) {
                            if (this.boughtCourse.courses[i] === course.id) {
                                this.boughtCourse.courses.splice(i, 1)
                            }
                        }
                    }
                })
            }

            this.calcTotalSum()
        },
        calcTotalSum() {
            this.totalPrice = 0
            this.diffTotalPrice = 0

            for (let courseId of this.boughtCourse.courses) {
                this.getModule.courses.forEach(course => {
                    if (course.id === courseId) {
                        this.totalPrice += course.price

                        if (this.getFoundPromo !== null) {
                            for (let promo of this.getFoundPromo.promos) {
                                if (course.type.name === promo.name && (!course.boughtCourses.length || !course.boughtCourses.some(el => el.isPayed === true))) {
                                    if (this.getFoundPromo.isPercent) {
                                        this.totalPrice -= (course.price / 100) * (this.getFoundPromo.discount / 100)
                                    } else {
                                        this.totalPrice -= this.getFoundPromo.discount
                                    }
                                }
                            }
                        }
                    }
                })
            }

            this.getUserCourses.forEach(studentCourse => {
                if (studentCourse.course.module.id === this.course.moduleId) {
                    this.diffTotalPrice += studentCourse.course.price
                }
            })

            this.totalPrice -= this.diffTotalPrice
        },
        toDown() {
            let purchaseForCourse = document.querySelector('.form-register');
            let fixedBtn = document.getElementById('goToPurchase');

            const termsObserverCallback = (purchaseForCourse) => {
                if (purchaseForCourse[0].isIntersecting) {
                    fixedBtn.style.display = 'none';
                } else {
                    fixedBtn.style.display = 'block';
                }
            };

            const termsObserverOptions = {}
            const termsObserver = new IntersectionObserver(termsObserverCallback, termsObserverOptions)

            termsObserver.observe(purchaseForCourse)
        },
        bought() {
            this.getModule.courses.map((el) => {
                this.getUserCourses.forEach(course => {
                    if (course.course.type.id === el.type.id) {
                        this.boughtCourse.courses.shift()
                    }
                })
            })
            this.searchOneCourse(this.boughtCourse.courses)
                .then(() => {
                    if (this.getOneCourse.members >= this.getOneCourse.countOfStudents) {
                        this.$refs['modal-success'].show()

                        return
                    }

                    this.fetchBoughtCourse({
                        courses: this.boughtCourse.courses.sort((a, b) => a - b),
                        promo: this.validPromo,
                        hasDiscount: this.hasDiscount
                    })
                        .then(() => {
                            this.payme.amount = 0
                            this.getBoughtCourse.forEach((boughtCourse) => {
                                this.payme.amount += boughtCourse.price
                            })
                            this.payme.transactionId = this.getBoughtCourse[0].paymeTransaction
                            this.payme.description = 'Dasturchi maslahati uchun to\'lov'
                            this.detailDto.items[0].price = this.payme.amount
                            this.payme.detail = btoa(JSON.stringify(this.detailDto))
                        })
                        .then(() => {
                            this.$refs.paymeButton.click()
                        })
                        .catch((reason) => {
                            switch (reason) {
                                case 'You already payed for this course':
                                    this.$refs['you-payed'].show()
                                    break

                                case 'This promo is expired':
                                case 'Promo is not found':
                                    this.$refs['modal-promo'].show()
                                    break

                                case 'You should buy dependency course':
                                    this.$refs['can-not-bought'].show()
                                    break
                            }
                        })
                })
        },
        findPromo() {
            this.findPromoCode({
                promo: this.promo,
                courseIds: this.boughtCourse.courses
            })
                .then(() => {
                    if (this.getFoundPromo === null) {
                        this.$refs['modal-promo'].show()
                    }

                    this.validPromo = this.promo
                    this.calcTotalSum()
                })
                .catch((reason) => {
                    if (reason === 'Promo is not found' || reason === 'This promo is expired') {
                        this.$refs['modal-promo'].show()
                    }
                })
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$refs['modal-promo'].hide()
            this.$refs['you-payed'].hide()
            this.$refs['can-not-bought'].hide()
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },
        fetchAndShowMiddleModules() {
            this.fetchModulesMiddle()
                .then(() => {
                    for (let i = 0; i < this.getModules.length; i++) {
                        if (this.getModules[i].courses[0].countOfStudents > 0 && this.getModules[i].courses[0].isActive) {
                            this.course.moduleId = this.getModules[i].id
                            break
                        }
                    }

                    if (this.course.moduleId !== null) {
                        this.hasActiveCourses = true
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fetchAndShowMiddleCourses() {
            for (let course of this.getModule.courses) {
                if (this.getToken && this.getUserCourses.length) {
                    this.getUserCourses.forEach(studentCourse => {
                        if (course.isActive || (!course.isActive && course.type.id === studentCourse.course.type.id)) {
                            if (this.boughtCourse.courses.find(el => el === course.id) === undefined) {
                                this.boughtCourse.courses.push(course.id)
                            }
                        }
                    })
                } else {
                    if (course.isActive) {
                        if (this.boughtCourse.courses.find(el => el === course.id) === undefined) {
                            this.boughtCourse.courses.push(course.id)
                        }
                    }
                }

                if (course.teacher !== null) {
                    this.course.teacher.givenName = course.teacher.givenName
                    this.course.teacher.familyName = course.teacher.familyName
                }
            }
        },
    },
    created() {
        this.findOneCourseMiddle(this.course.moduleId)
        this.interval = setInterval(() => {
            this.findOneCourseMiddle(this.course.moduleId)
                .then(() => {
                    this.getFindCourses
                })
        }, 60000 * 5)
    },
    destroyed() {
        clearInterval(this.interval)
    },
    watch: {
        'course.moduleId'() {
            this.boughtCourse.courses = []

            this.fetchModule(this.course.moduleId)
                .then(() => {
                    this.hasActiveCourses = true
                    this.fetchAndShowMiddleCourses()
                    this.myBoughtCourses = this.getMineBoughtCoursesList.length
                    this.calcTotalSum()
                })
        },
    },
    mounted() {
        this.show = true
        this.fetchUser()
            .then(() => {
                this.fetchUserCourses(this.getUserInfo.id)
                    .then(() => {
                        this.fetchAndShowMiddleModules()
                    })
            })
            .catch(() => {
                this.fetchAndShowMiddleModules()
            })

        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
}

.fadeInLeft {
    animation-name: fadeInLeft;
    animation-duration: 1s;
}

.course-check {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.promo-text {
    font-size: 20px;
}

.bg-color {
    border-radius: 10px;
    padding: 20px;
    background-color: #e5ffe5;
}

.course-check:hover {
    color: #80007F;
}

.border-module {
    border: 1px solid #80007F;
    padding: 10px;
    border-radius: 10px;
}

.border-cover {
    padding: 5px 0;
    border-bottom: 1px solid #80007F;
}

input[type="checkbox"] {
    display: block;
    accent-color: #80007F;
    width: 22px;
    height: 22px;
    line-height: 22px;
    content: "";
}

.course-color {
    font-weight: bold;
    color: #80007F;
}

.fadeInRight {
    animation-name: fadeInRight;
    animation-duration: 1s;
}

.course-title-box:hover {
    background-color: #990098;
}

.discount {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: red;
    text-decoration: underline;
}

.heading {
    color: #FFFFFF;
}

.align {
    text-align: justify;
}

.font {
    font-family: 'Comfortaa', cursive;
}

.form-register {
    padding-top: 0;
}

.text-center {
    text-decoration: black;
    font-size: 2em;
    font-weight: bold;
}

.Tech a {
    font-size: 18px;
    line-height: 20px;
    text-align: right;
    text-decoration-line: underline;
    padding-top: 20px;
    float: right;
}

.divp6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-align: justify;
}

.divp6 li {
    margin-left: 10%;
    margin-right: 10%;
    padding-bottom: 15px;
}

.Tech {
    font-family: Comfortaa, cursive;
    font-style: normal;
    font-weight: normal;
    background: #80007F;
    width: 100%;
    padding: 30px 0 70px;
    height: auto;

}

.courseAddInfo {
    border: solid #1B6EC1 2px;
    max-width: 200px;
    min-width: 160px;
    min-height: 134px;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

h1 {
    animation: bounceIn;
    animation-duration: 2s;
}

.img {
    height: 300px;
    width: 250px;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    margin-right: 20px;
}

.information {
    margin: auto;
}

.course-title-box {
    color: #fff;
    background-color: #80007F;
    min-height: 164px;
    width: 100%;
}

.course-title {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    margin: auto;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

.advice {
    padding: 30px 20%;
}

.row {
    margin: auto;
}

.course-subtitle {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    margin: auto;
}

.course {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 27px;
}

.sent {
    padding: 25px 20% 110px 20%;
}

.senter {
    padding: 110px 20%;
}

.login {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-decoration: none;
}

.senter h5 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    padding: 85px 10% 15px 12.5%;
}

.senter h6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    margin: 30px 0;
}

.sent h6 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 28px;
    margin: 30px 0;
}

.divp7 {
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    text-align: justify;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.form-control {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
    padding-left: 10px;
    outline: none !important;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.select .btn-primary {
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    border-radius: 5px;
    background-color: #80007F !important;
    border: none;
    font-family: Comfortaa, cursive;
    font-size: 16px;
    width: 100%;
    height: 40px;
    margin: 0 10px 0 0;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098 !important;
}

.text-bold {
    font-weight: 900;
    font-size: 18px;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-secondary {
    border-radius: 5px;
    border: none;
    background-color: #027610;
    font-family: Comfortaa, cursive;
    font-size: 16px;
    width: 100%;
    height: 40px;
}

.btn-secondary:hover {
    background-color: #03ab18;
}

.text-bold {
    font-weight: 900;
    font-size: 18px;
}

.course-reg-button {
    z-index: 1;
}
</style>
